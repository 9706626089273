import React from "react";
import { useLocation } from "react-router-dom";

export const ConfigContext = React.createContext();

const ConfigProvider = (props) => {
  const location = useLocation();
  const path = React.useMemo(() => location?.pathname, [location]);

  const activeModule = React.useMemo(() =>
    path.indexOf('/commune/') === 0 ? 1
      : path.indexOf('/patrimoine/') === 0 ? 2
        : path.indexOf('/consommations/') === 0 ? 3
          : path.indexOf('/dashboards') === 0 ? 4
            : 0
    , [path]);

  return (
    <ConfigContext.Provider
      value={{
        activeModule,
      }}
    >
      <>{props.children}</>
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
