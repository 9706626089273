import * as React from "react";

import "../App.css";
import { useOutlet } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";

import AppBarComponent from "./header";
import Footer from "./footer";
import ConfigProvider from "../context/ConfigContext";

import "../assets/css/main-style.css";

const Root = () => {
  const outlet = useOutlet();

  return (
    <>
      {/* main content */}
      <CssBaseline />
      <ConfigProvider>
        <AppBarComponent />
        {outlet}

        <Footer />
      </ConfigProvider>
    </>
  );
};

export default Root;
