import * as React from "react";

import LoginIcon from "@mui/icons-material/Login";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import backgroundImage from "./../../assets/images/acte-lg.png";
import { styled } from "@mui/material/styles";

import { Form, Formik } from "formik";
import * as yup from "yup";

import useAuthService from "./../../services/shared/authService.js";

const Img = styled("img")({
  maxWidth: "100%",
  maxHeight: "100%",
});

const Login = () => {
  const { login } = useAuthService();

  return (
    <>

      <Grid container alignItems="center" justifyContent="center">
        <Grid
          item
          lg={6} md={6} sm={12} xs={12}
          sx={{ py: { xs: 2, sm: 4, md: 8, lg: 16 } }}
        >
          <Img src={backgroundImage} />
        </Grid>
        <Grid
          item
          lg={5} md={5} sm={11} xs={11}
          sx={{ py: { xs: 2, sm: 4, md: 8, lg: 16 } }}
        >
          <Card
            sx={{
              boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)!important",
              borderTop: "3px solid #006aa4",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack alignItems="center" justifyContent="center">
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                margin={1}
                sx={{
                  fontWeight: "500",
                  fontStyle: "italic",
                  fontFamily: "system-ui",
                  marginTop: 2,
                }}
              >
                Connexion
              </Typography>
            </Stack>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={yup.object().shape({
                email: yup
                  .string()
                  .email("Le format de l'email est invalide")
                  .required("Veuillez saisir votre adresse mail."),
                password: yup.string().required("Veuillez saisir votre mot de passe."),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  await login(values);
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {({ values, errors, isSubmitting, handleChange, touched }) => (
                <Form>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    margin={2}
                  >
                    <TextField
                      id="email-input"
                      label={<Typography className="form-label">Email</Typography>}
                      type="text"
                      variant="standard"
                      size="small"
                      name="email"
                      autoComplete="username"
                      value={values.email}
                      onChange={handleChange}
                      style={{ width: "50%" }}
                      inputProps={{ style: {padding: "4px"}}}

                      error={touched.email && errors.email}
                      helperText={touched.email && errors.email
                        ? errors.email
                        : ""}
                    />
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    margin={2}
                  >
                    <TextField
                      id="password-input"
                      label={<Typography className="form-label">Mot de passe</Typography>}
                      type="password"
                      variant="standard"
                      size="small"
                      name="password"
                      value={values.password}
                      autoComplete="current-password"
                      onChange={handleChange}
                      style={{ width: "50%"}}
                      inputProps={{ style: {padding: "4px"}}}

                      error={touched.password && errors.password}
                      helperText={touched.password && errors.password
                        ? errors.password
                        : ""}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    margin={2}
                  >
                    <Button
                      type="submit"
                      variant="outlined"
                      startIcon={<LoginIcon />}
                    >
                      Se connecter
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
