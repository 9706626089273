import React, { useState, useCallback, useMemo } from 'react';

export const AuthContext = React.createContext();

const AuthProvider = (props) => {
  const [user, _setUser] = useState();
  const [commune, _setCommune] = useState();

  const signOut = useCallback(() => {
    _setCommune(null);
    _setUser(null);

    sessionStorage.clear();
  }, []);

  const setCommune = useCallback((communeData) => {
    try {
      const commune = {
        id: communeData.id,
        nom: communeData.nom,
        logo: communeData.logo,
        longitude: communeData.longitude,
        latitude: communeData.latitude,
        gouv: communeData.gouv,
        gouv_nom: communeData.gouv_nom,
      }

      _setCommune({ ...commune });

      sessionStorage.setItem(
        'commune',
        JSON.stringify(commune)
      );

      return true;
    }
    catch {
      _setCommune(null);
      sessionStorage.removeItem('commune');
      return false;
    }
  }, [])

  const setUser = useCallback((userData) => {
    try {
      const user = {
        id: userData.id,
        nom: userData.nom,
        groups: userData.groups,
        is_agent: userData.is_agent,
        is_gest: userData.is_gest,
        is_resp: userData.is_resp,
        commune: userData.commune,
        gouv: userData.gouv,
      }

      _setUser({
        ...user,
      });

      sessionStorage.setItem(
        'user',
        JSON.stringify(user)
      );

      return true;
    }
    catch (err) {
      _setUser(null);
      sessionStorage.removeItem('user');
      return false;
    }
  }, [])

  const signIn = useCallback((userData, communeData) => {
    let success = setUser(userData);

    if (success && communeData) {
      success = setCommune(communeData);
    }

    if (!success) {
      signOut();
    }

    return success;
  }, [ setCommune, setUser, signOut]);

  useMemo(() => {
    // console.log('useMemo');

    const userData = JSON.parse(sessionStorage.getItem('user'));
    const communeData = JSON.parse(sessionStorage.getItem('commune'));

    signIn(userData, communeData);

  }, [signIn]);

  const permissions = useMemo(() => {
    // console.log('permissions');
    const getPermissions = (group) => {
      return {
        write: user.is_agent && user.groups.includes(group),
        read: true,
        export: (user.is_agent && user.groups.includes(group)) || user.is_resp || user.is_gest,
        comment: (user.is_agent && user.groups.includes(group)) || user.is_resp || user.is_gest,
      }
    }

    if (user) {
      return {
        batiments: getPermissions('Patrimone_Batiment'),
        eclairage: getPermissions('Patrimone_Eclairage'),
        parc: getPermissions('Patrimone_Parc'),
        consommations: getPermissions('Consommations'),
        communeInfos: getPermissions('Commune_Infos'),
      };
    } else {
      return null;
    }
  }, [user]);

  const loggedIn = useMemo(() => {
    return Boolean(user)
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        user,
        commune,
        loggedIn,
        permissions,
        signIn,
        signOut,
        setCommune,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
