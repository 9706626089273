import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import MapDialog from "../components/dialogs/mapDialog";

import tn from "../assets/images/tn-stat.png";

import { AuthContext } from "../context/AuthContext";
import { getMediaUrl } from "../_utils/media";

const Home = () => {
  const { loggedIn, commune, user, setCommune } = useContext(AuthContext);
  const [displayCommuneDialog, setDisplayCommuneDialog] = useState(false);

  useEffect(() => {
    document.title = "Accueil- Plateforme de comptabilité énergetique communale";
  }, [])

  return (
    <>
      <Stack alignItems="center" >
        <Box sx={{ width: "90%" }} >
          <Grid container
            sx={{ marginTop: "1rem" }}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
          >
            <Grid
              item
              xs={12}
              sm={loggedIn ? 6 : 12}
              md={loggedIn ? 8 : 12}
              lg={loggedIn ? 8 : 12}
              className="home-intro-box home-blue-box"
            >
              <Typography className="home-intro-text" variant="h3">
                Plateforme de comptabilité énergetique communale
              </Typography>
              <Typography className="home-intro-text" variant="h5">
                Projet d'appui au plan national de transition énergétique des
                communes en Tunisie
              </Typography>

              {loggedIn ? ("") : (
                <Link to="/login" className="home-blue-link ">
                  Connexion
                </Link>
              )}
            </Grid>
            {loggedIn ? (
              <>
                <Grid
                  item
                  xs={12} sm={6} md={4} lg={4}

                  container
                  direction="column"
                  justifyContent="space-around"
                  alignItems="center"
                  className="home-intro-box home-outline-box"
                >

                  {user.is_gest ? (
                    <Button
                      className="home-select-button"
                      // startIcon={<AddLocationAltIcon />}
                      variant="outlined"
                      onClick={() => setDisplayCommuneDialog(true)}
                    >
                      Commune de
                    </Button>
                  ) : (
                    <Typography >
                      Commune de
                    </Typography>
                  )}

                  <Typography variant="h3" gutterBottom>
                    {commune ? commune?.nom : "..."}
                  </Typography>

                  {commune && commune?.logo ? (
                    <>
                      <img
                        src={getMediaUrl(commune?.logo)}
                        alt="Tableaux de bord"
                        className="commune-logo-large"
                      />
                    </>
                  ) : ("")}

                </Grid>

              </>
            ) : ("")}

          </Grid >
          <Box className="home-intro-box home-gray-box" >
            <Grid container >
              <Grid item xs={12} sm={true} md={true} lg={true} >
                <Typography gutterBottom className="home-intro-comment" >
                  Accès libre au grand public
                </Typography>
                <Typography variant="h4" gutterBottom>
                  Soyez informé, soyez acteur
                </Typography>

                <Typography variant="h6" gutterBottom >
                  Consulter les tableaux de bord globaux au niveau <span>
                    <Link to={`/dashboards${loggedIn && commune ? "" : "/public"}/national`} className="home-green-link ">
                      National
                    </Link>
                  </span>, par <span>
                    <Link to={`/dashboards${loggedIn && commune ? "" : "/public"}/gouvernorat`} className="home-green-link ">
                      Gouvernorat
                    </Link>
                  </span> ou par <span>
                    <Link to={`/dashboards${loggedIn && commune ? "" : "/public"}/commune`} className="home-green-link ">
                      Commune
                    </Link>
                  </span>.
                </Typography>

              </Grid>
              <Grid container direction="row"
                alignItems="center" justifyContent="center" item xs={12} sm="auto" md="auto" lg="auto" >
                <img src={tn} alt="Tableaux de bord" className="home-tn-img" />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Stack >


      <MapDialog
        open={displayCommuneDialog}
        level='commune'
        onClose={() => {
          setDisplayCommuneDialog(false);
        }}
        onConfirm={(location) => {
          setCommune(location);
          setDisplayCommuneDialog(false);
        }}
      >
      </MapDialog>
    </>
  );
};

export default Home;
