import * as React from "react";
import { Link } from 'react-router-dom';

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import logoActe from "../../assets/images/acte.png";
import logoAnme from "../../assets/images/anme.png";
import logoSeco from "../../assets/images/seco.png";

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';


const Footer = () => {
  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        className="footer"
      >
        <Box sx={{ width: "90%" }}>
          <Grid
            container
            spacing={1}
            padding={1}
            direction="row"
            alignItems="center"
          >
            <Grid item xs={true} sm={true} md={true} lg={true} >
              <Link to="http://acte.tn/fr">
                <img src={logoActe} alt="ACTE" style={{ paddingRight: ".5rem" }} />
              </Link>
              <Link to="https://www.anme.tn">
                <img src={logoAnme} alt="ANME" style={{ paddingRight: ".5rem" }} />
              </Link>
              <Link to="https://www.seco-cooperation.admin.ch/secocoop/fr/home/laender/tunisie.html">
                <img src={logoSeco} alt="SECO" />
              </Link>
            </Grid>

            <Grid item xs="auto" sm="auto" md="auto" lg="auto">
              <Link to="https://www.facebook.com/ACTETunisia">
                <FacebookIcon sx={{ color: "#56b953" }} />
              </Link>
              <Link to="https://www.linkedin.com/company/programme-acte">
                <LinkedInIcon sx={{ color: "#56b953" }} />
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default Footer;
