import React from "react";

import LinearProgress from '@mui/material/LinearProgress';


const Loader = () => {

  return (
    <div className="linear-loader">
      <LinearProgress color="primary" />
    </div>
  );
};

export default Loader;
