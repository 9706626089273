import * as React from "react";

import Box from "@mui/material/Box";
import Stack from '@mui/material/Stack';
import Grid from "@mui/material/Grid";
import drapeau from "./../../assets/images/drapeau-tunisie.png";
import Typography from '@mui/material/Typography';

const AppTopBar = () => {
  return (
    <Stack alignItems="center" className="app-top-bar" >
      <Box sx={{ width: "90%" }} >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item lg={true} md={true} sm={true} xs={8} >
            <Typography className="app-top-bar-title" >
              Comptabilité energétique communale
            </Typography>
          </Grid>
          <Grid item lg='auto' md='auto' sm='auto' xs='auto' >
            <img src={drapeau} className="app-top-bar-image" alt="Drapeau Tunisie" />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};
export default React.memo(AppTopBar);
