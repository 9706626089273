import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import img404 from "../../assets/images/404.png"

export default function ErrorPage({ status }) {

  return (
    <Stack
      textAlign='center'
      width={1}
      height={1}
      justifyContent='center'
      alignItems='center'
      minHeight='70vh'
    >
      {status === 401 ? (
        <>
          <Typography variant="h4" mb='16px' fontSize={32}>
            Utilisateur non authentifié
          </Typography>
          <Typography variant="body1" mb='48px'>
            Désolé, mais votre session est terminée ou vous n'êtes pas connecté.<br />
            Veuillez vous connecter à nouveau.
          </Typography>
        </>
      ) : status === 403 ? (
        <>
          <Typography variant="h4" mb='16px' fontSize={32}>
            Permission refusée
          </Typography>
          <Typography maxWidth={500} variant="body1" mb='48px'>
            Désolé, mais il semble que vous n'avez pas les permissions nécessaires pour accéder à la fonction/page demandée.
            Veuillez vérifier que vous droits d'accès aux prés de l'administrateur.
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h4" mb='16px' fontSize={32}>
            Page non retrouvée
          </Typography>
          <Typography variant="body1" mb='48px'>
            Désolé, nous n'avons pas trouvé la page que vous recherchez.<br />
            Peut-être avez-vous mal saisi l'URL ?<br />
            Vérifiez que vous avez entré correctement l'url.
          </Typography>
          <Box mb='32px' component='img' src={img404}></Box>
        </>
      )}
      <Link to="/index" className="home-green-link ">
        Retour à la page d'accueil.
      </Link>
    </Stack>
  )
}