import { useCallback, useRef, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router";


const useHttpClient = () => {
  const activeHttpRequest = useRef([]);
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();

  let isRefreshing = false;
  const refreshAndRetryQueue = [];

  axios.defaults.baseURL = process.env.REACT_APP_API_SERVER_URL;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common['accept'] = 'application/json';

  const setToken = useCallback((tokenData) => {
    try {
      const token = {
        access: tokenData.access,
        refresh: tokenData.refresh
      }
      sessionStorage.setItem(
        'token',
        JSON.stringify(token)
      );

      return true;
    } catch (error) {
      sessionStorage.removeItem('token');
      return false;
    }
  }, []);

  const refreshToken = useCallback(async () => {
    const token = JSON.parse(sessionStorage.getItem('token'));
    const refreshToken = token?.refresh;

    if (refreshToken) {
      const response = await axios.post(
        'api/token/refresh',
        { refresh: refreshToken }
      );

      if (response.status === 200) {
        // console.log("Token refresh");
        return setToken(response.data);
      } else {
        return Promise.reject('Refresh token is expired.');
      }
    } else {
      return Promise.reject('Refresh token not available.');
    }
  }, [setToken]);

  axios.interceptors.request.use(function (config) {
    const token = JSON.parse(sessionStorage.getItem('token'));

    if (token?.access) {
      config.headers.Authorization = `Bearer ${token.access}`;
    }

    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error)
  });

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (error.response && error.response.status === 401) {
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            const result = await refreshToken();

            if (result) {
              // Retry all requests in the queue with the new token
              refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                axios
                  .request(config)
                  .then((response) => resolve(response))
                  .catch((err) => reject(err));
              });

              refreshAndRetryQueue.length = 0;

              // Retry the original request
              return axios(originalRequest);
            } else {
              throw new TypeError('A server/network error occurred on login refresh.');
            }

          } catch (refreshError) {
            signOut();
            navigate("/401");
          } finally {
            isRefreshing = false;
          }
        }

        // Add the original request to the queue
        return new Promise((resolve, reject) => {
          refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
        });
      } else if (error.response){
        console.log('Error: The request was made but the server response with a status that falls out the range of 2xx)');
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);        

      } else if (error.request) {
        console.log('Error: The request was made but no response form the server)');
        console.log(error.message);
        console.log(error.request);
      } else {
        console.log('Error: Something happened in setting up the request that triggered an Error');
        console.log(error.message);
      }

      // console.log(error.config);      
      return error;
    }
  );

  const sendRequest = useCallback(
    async (url, method = "get", body = null) => {
      try {
        const response = await axios[method](
          url,
          method === "post" || method === "put" ? body : null
        );
        // const result = await response;
        // console.log(response);
        return response;
      } catch (err) {
        console.log(err);
        throw err;
      }
    }
    ,
    []
  );

  useEffect(() => {
    let activeHttpRequestRefValue = null;

    if (activeHttpRequest.current) {
      activeHttpRequestRefValue = activeHttpRequest.current;
    }

    return () => {
      if (activeHttpRequestRefValue) {
        activeHttpRequestRefValue.forEach((abortCtrl) => abortCtrl.abort());

        // The ref value 'activeHttpRequest.current' will likely have changed by the 
        // time this effect cleanup function runs. If this ref points to a node rendered 
        // by React, copy 'activeHttpRequest.current' to a variable inside the effect, 
        // and use that variable in the cleanup function  react-hooks/exhaustive-deps

        // activeHttpRequest.current.forEach((abortCtrl) => abortCtrl.abort());

      }
    };
  }, []);
  return {
    setToken,
    sendRequest,
  };
};

export default useHttpClient;
