import * as React from 'react';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';

const BaseDialog = ({ variant, title, open, disableSelect, smallSize, onClose, onConfirm, children})  => {
  return (
    <Dialog 
      open={open}
      onClose={onClose} 
      maxWidth= {smallSize ? "xs" : "sm"} 
      fullWidth
      aria-labelledby="dialog-title"
    >
      <DialogTitle id="dialog-title">{title} ..</DialogTitle>
      <IconButton 
        onClick={onClose}
        aria-label="Fermer"
        sx={{
          position: 'absolute',
          right: 12,
          top: 12,
        }}
        >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        {children }
      </DialogContent>
      <DialogActions sx={{ padding: ".5rem 1.5rem"}}>
        <Button 
          onClick={onClose}
          className="dialog-button dialog-cancel-button"
        >
          Annuler
        </Button>
        {variant === "delete" ? (
          <Button 
            onClick={onConfirm}
            className= "dialog-button dialog-delete-button"
          >
            Supprimer
          </Button>
        ) : variant === "map" ? (
          <Button 
            onClick={onConfirm}
            disabled={disableSelect}
            className= "dialog-button dialog-edit-button"
          >
            Ouvrir
          </Button>
        ) : variant === "edit"  ? (
          <Button form="edit-form" type="submit" className="dialog-button dialog-edit-button">
            Enregistrer
          </Button>
        ) : ("")}
        
      </DialogActions>
    </Dialog>
  );
};

export default BaseDialog;
