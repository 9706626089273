import React, { useState, useCallback, useRef } from 'react';
import { SVGMap } from "react-svg-map";
import Tunisia from '../../_utils/tunisia';

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useCommunesService from '../../services/commune/communesService';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import BaseDialog from './baseDialog';

// import "react-svg-map/lib/index.css";

const MapDialog = ({ open, level, onClose, onConfirm }) => {

  const [communes, setCommunes] = useState(null);
  const [selectedCommune, setSelectedCommune] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [pointedLocation, setPointedLocation] = useState(null);
  const selectedLocationIdRef = useRef(selectedLocation?.id);


  const { getCommunesByGouv } = useCommunesService();

  const getLocationName = useCallback((svgLocation) => {
    return svgLocation?.attributes?.name?.value;
  }, []);

  const handleLocationMouseOver = useCallback((event) => {
    const locationName = event.target.attributes?.name?.value;
    setPointedLocation(locationName);
  }, []);

  const handleLocationMouseOut = useCallback(() => {
    setPointedLocation(null);
  }, []);

  const handleLocationClick = useCallback((event) => {
    const getData = async (id) => {
      const response = await getCommunesByGouv(id);
      setCommunes(response ? response : [])
    }

    const location = event.target;

    if (location.id !== selectedLocationIdRef.current) {
      location.focus();
      const id = event.target.id;

      setSelectedCommune(null);
      setSelectedLocation(location);
      selectedLocationIdRef.current = location.id;

      if (level === 'commune') getData(id);
    }
  }, [level, getCommunesByGouv]);

  const isLocationSelected = useCallback((location) => {
    return selectedLocationIdRef.current &&
      location.id === selectedLocationIdRef.current;
  }, []);

  const handleChange = useCallback((event) => {
    setSelectedCommune(event.target.value);
  }, []);

  // console.log(selectedCommune);


  return (
    <BaseDialog
      variant={"map"}
      title={"Sélectionner " + (level === 'commune' ? "une commune .." : "un gouvernorat ..")}
      open={open}
      smallSize={level !== 'commune'}
      onClose={onClose}
      disableSelect={!Boolean((level === 'commune' && selectedCommune !== null) || (level === 'gouv' && selectedLocation !== null))}
      onConfirm={() => {
        if (level === 'commune' && selectedCommune) {
          const commune = communes.find(value => value.id === Number(selectedCommune));
          if (commune) onConfirm(commune);
        } else if (level === 'gouv' && selectedLocation) {
          const gouv = { id: selectedLocation.id, nom: getLocationName(selectedLocation) }
          onConfirm(gouv);
        }
      }}
    >
      <Grid container >
        <Grid item
          xs={level === 'commune' ? 5 : 12}
          sm={level === 'commune' ? 5 : 12}
          md={level === 'commune' ? 5 : 12}
          lg={level === 'commune' ? 5 : 12}
        >
          <div className="svg-tn-map-tooltip">
            {pointedLocation}
          </div>
          <div className="svg-tn-map-bloc">
            <SVGMap
              map={Tunisia}
              className="svg-tn-map"
              locationClassName="svg-tn-map-gouv"
              onLocationMouseOver={handleLocationMouseOver}
              onLocationMouseOut={handleLocationMouseOut}
              onLocationClick={handleLocationClick}
              isLocationSelected={isLocationSelected}
            />
          </div>
          {level === 'gouv' ? (
            <FormLabel
              id="commune-radio-group-label"
              className="form-label"
            >
              Grouvernorat: {getLocationName(selectedLocation)}
            </FormLabel>
          ) : ("")}
        </Grid>
        {level === 'commune' ? (
          <Grid item xs={7} sm={7} md={7} lg={7} sx={{ paddingLeft: "2px" }}>
            <FormControl fullWidth>
              <FormLabel
                id="commune-radio-group-label"
                className="form-label"
              >
                Grouvernorat: {getLocationName(selectedLocation)}
              </FormLabel>
              {communes && communes?.length > 0 ? (
                <RadioGroup
                  aria-labelledby="commune-radio-group-label"
                  name="commune"
                  className="commune-radio-group"
                  onChange={handleChange}
                >
                  {communes.map(value => {
                    return <FormControlLabel
                      key={value.id}
                      value={value.id}
                      control={<Radio
                        size="small"
                        sx={{
                          paddingBottom: "4px !important",
                          paddingLeft: "9px !important",
                          paddingRight: "9px !important",
                          paddingTop: "4px !important",
                        }}
                      />}
                      label={<Typography
                        className="form-radio-label"
                      >
                        {value.nom}
                      </Typography>}
                    />
                  })}
                </RadioGroup>
              ) : ("")}
            </FormControl>
          </Grid>
        ) : ("")}

      </Grid>

    </BaseDialog>
  );
};

export default React.memo(MapDialog);
