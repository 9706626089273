import { useCallback, useContext } from "react";
import { toast } from "react-toastify";

import useHttpClient from "../../hooks/useHttpClient";
import { useNavigate } from "react-router";

import { AuthContext } from "../../context/AuthContext";

const useSharedDataService = () => {
  const { sendRequest } = useHttpClient();
  const { signOut } = useContext(AuthContext);
  const navigate = useNavigate();

  const getErrorMessage = useCallback((item, err) => {
    let message = "";

    if (err?.response?.status === 400) {         // BadRequest
      if (item === "budget") {
        for (let key in err.response.data) {
          if (key === "annee") {
            message = "L'année doit être comprise entre 2010 et l'année précédente.";
          } else if (key === "unique_data") {
            message = "Un enregistrement avec la même année existe déjà.";
          }
        }
      } else if (item === "population") {
        for (let key in err.response.data) {
          if (key === "annee") {
            message = "L'année doit être comprise entre 2010 et l'année précédente.";
          } else if (key === "unique_data") {
            message = "Un enregistrement avec la même année existe déjà.";
          }
        }
      } else if (item === 'projet') {
        for (let key in err.response.data) {
          if (key === "date") {
            message = "La date de mise en service doit être comprise entre 01/01/2010 et aujourd'hui.";
          } else if (key === "object_not_found") {
            message = "Le projet de la requête est introuvable.";
          }
        }
      } else if (item === "batiment") {
        for (let key in err.response.data) {
          if (key === "annee_construction") {
            message = "L'année de construction doit être comprise entre 1800 et l'année précédente.";

          } else if (key === "unique_data") {
            message = "Un bâtiment avec le même nom existe déjà.";
          } else if (key === "object_not_found") {
            message = "Le bâtiment de la requête est introuvable.";
          }
        }
      } else if (item === "equipement") {
        for (let key in err.response.data) {
          if (key === "annee_installation") {
            message = "L'année d'installation doit être comprise entre 1900 et l'année précédente.";

          } else if (key === "object_not_found") {
            message = "L'enregistrement des équipements de la requête est introuvable.";
          }
        }
      } else if (item === "lampe-bat") {
        for (let key in err.response.data) {
          if (key === "unique_data") {
            message = "Des lampes de même type existent déjà dans le bâtiment.";
          } else if (key === "object_not_found") {
            message = "L'enregistrement des lampes de la requête est introuvable.";
          }
        }
      } else if (item === 'armoire') {
        for (let key in err.response.data) {
          if (key === "date_installation") {
            message = "La date d'installation doit être comprise entre 01/01/1950 et aujourd'hui.";
          } else if (key === "unique_data") {
            message = "Une armoire avec la même adresse existe déjà.";
          } else if (key === "object_not_found") {
            message = "L'armoire de la requête est introuvable.";
          }
        }
      } else if (item === 'depart') {
        for (let key in err.response.data) {
          if (key === "unique_data") {
            message = "Un départ avec le même numéro existe déjà.";
          } else if (key === "object_not_found") {
            message = "Le départ de la requête est introuvable.";
          }
        }
      } else if (item === "point-lumineux") {
        for (let key in err.response.data) {
          if (key === "unique_data") {
            message = "Des points lumineux avec les même données existent déjà.";
          } else if (key === "object_not_found") {
            message = "L'enregistrement des points lumineux de la requête est introuvable.";
          }
        }
      } else if (item === "zone-eclaiarge") {
        for (let key in err.response.data) {
          if (key === "unique_data") {
            message = "Une zone d'eclairage avec le même nom existe déjà.";
          } else if (key === "object_not_found") {
            message = "La zone de l'eclairage de la requête est introuvable.";
          }
        }
      } else if (item === "lampe-ep") {
        for (let key in err.response.data) {
          if (key === "unique_data") {
            message = "Des lampes de même type existent déjà.";
          } else if (key === "object_not_found") {
            message = "L'enregistrement des lampes de la requête est introuvable.";
          }
        }
      } else if (item === "compteur") {
        for (let key in err.response.data) {
          if (key === "unique_data") {
            message = "Un compteur avec le même numéro de référence existe déjà.";
          } else if (key === "object_not_found") {
            message = "Le compteur de la requête est introuvable.";
          }
        }
      } else if (item === 'vehicule') {
        for (let key in err.response.data) {
          if (key === "date_circulation") {
            message = "La date de circulation doit être comprise entre 01/01/1950 et aujourd'hui.";
          } else if (key === "unique_data") {
            message = "Un véhicule/engin avec le même numéro de matricule existe déjà.";
          } else if (key === "object_not_found") {
            message = "Le véhicule/engin de la requête est introuvable.";
          }
        }
      } else if (item === 'personnel') {
        for (let key in err.response.data) {
          if (key === "annee") {
            message = "L'année doit être comprise entre 2010 et l'année précédente.";
          } else if (key === "unique_data") {
            message = "Des enregistrements de même type/catégorie/année existent déjà.";
          } else if (key === "object_not_found") {
            message = "Les données du presonnel du parc de la requête sont introuvables.";
          }
        }
      } else if (item === 'depense') {
        for (let key in err.response.data) {
          if (key === "annee") {
            message = "L'année doit être comprise entre 2010 et l'année précédente.";
          } else if (key === "unique_data") {
            message = "Des enregistrement de même catégorie/année existent déjà.";
          } else if (key === "object_not_found") {
            message = "Les données des dépenses du parc de la requête sont introuvables.";
          }
        }
      } else if (item === 'index-an') {
        for (let key in err.response.data) {
          if (key === "annee") {
            message = "L'année doit être comprise entre 2010 et l'année précédente.";
          } else if (key === "unique_data") {
            message = "Un index annuel de la même année existent déjà.";
          } else if (key === "object_not_found") {
            message = "Les index annuels de la requête sont introuvables.";
          }
        }
      } else if (item === 'index') {
        for (let key in err.response.data) {
          if (key === "annee") {
            message = "La date doit être comprise entre 01/01/2010 et aujourd'hui.";
          } else if (key === "unique_data") {
            message = "Une valeur d'index de la même date existent déjà.";
          } else if (key === "object_not_found") {
            message = "Les valeurs d'index de la requête sont introuvables.";
          }
        }
      } else if (item === 'consommation') {
        for (let key in err.response.data) {
          if (key === "annee") {
            message = "L'année doit être comprise entre 2010 et l'année précédente.";
          } else if (key === "quantite") {
            message = "La quantité doit être vide ou supérieure à 0.";
          } else if (key === "cout") {
            message = "Le coût doit être vide ou supérieur à 0.";
          } else if (key === "quantite_cout") {
            message = "La quantité et le coût ne peuvent pas être tous les deux vides.";
          } else if (key === "unique_data") {
            message = "Une consommation de la même énergie/année existe déjà.";
          }
        }
      }
    }

    if (message === "") {
      if (err?.response?.status === 400) {           // BadRequest
        message = "Les données de la requête sont incorrectes ou ne pouvent pas être interprétées.";
      } else if (err?.response?.status === 401) {    // Unauthenticated
        message = "Session terminée ou utilisateur non authentifié.";
      } else if (err?.response?.status === 403) {    // PermissionDenied
        message = "L'utilisateur ne'a pas les permissions nécessaires pour effectuer cette action.";
      } else if (err?.response?.status === 404) {    // NotFound
        message = "La ressource demandée n'est pas retouvée.";
      } else {
        message = "Erreur non identifiée.";
      }
    }

    return message;
  }, []);

  const handleErrors = useCallback((err, method, source, sourceText) => {

    if (err?.response?.status === 401) {            // Unauthenticated
      signOut();
      navigate("/401");
    } else if (err?.response?.status === 403) {    // PermissionDenied
      navigate("/403");
    } else if (err?.response?.status === 404) {    // NotFound
      navigate("/404");
    } else {
      const message = getErrorMessage(source, err);
      const title = method === 'post'
        ? "Erreur lors de l'enregistrement des données."
        : method === 'put'
          ? "Erreur lors de la modificatioin des données."
          : method === 'delete'
            ? "Erreur lors de la suppression des données."
            : `Erreur lors de ${sourceText}.`;

      toast.error(
        <>
          <span className="toast-title">{title}</span>
          <br></br>
          <span className="toast-message">{message}</span>
        </>
      )
    }
  }, [getErrorMessage, navigate, signOut]);


  const addRecord = useCallback(async (url, item, itemText, data) => {
    try {
      const response = await sendRequest(url, "post", data);
      console.log(response);

      if (response?.status === 201) {
        toast.success(
          <span className="toast-message">L'enregistrement [{itemText}] est crée avec succès.</span>
        );
        return response.data;
      } else {
        handleErrors(response, 'post', item)
      }
    } catch (err) {
      handleErrors(err, 'post', item)
    }
  }, [sendRequest, handleErrors]);

  const updateRecord = useCallback(async (url, item, itemText, data) => {
    try {
      const response = await sendRequest(url, "put", data);

      if (response?.status === 200) {
        toast.success(
          <span className="toast-message">L'enregistrement [{itemText}] est modifié avec succès.</span>
        );
        return response.data;
      } else {
        handleErrors(response, 'put', item);
      }
    } catch (err) {
      handleErrors(err, 'put', item);
    }
  }, [sendRequest, handleErrors]);

  const deleteRecord = useCallback(async (url, item, itemText) => {
    try {
      const response = await sendRequest(url, "delete");

      if (response?.status === 204) {
        toast.success(
          <span className="toast-message">L'enregistrement [{itemText}] est supprimé avec succès.</span>
        );
        return response.status;
      } else {
        handleErrors(response, 'delete', item );
      }
    } catch (err) {
      handleErrors(err, 'delete', item);
    }
  }, [sendRequest, handleErrors]);

  const getData = useCallback(async (url, item, itemText, publicRequest) => {
    try {
      const response = await sendRequest(url, "get");
      if (response?.status === 200) {
        return response.data;
      } else {
        handleErrors(response, 'get', item, itemText );
      }
    } catch (err) {
      handleErrors(err, 'get', item, itemText );
    }
  }, [sendRequest, handleErrors]);

  return {
    addRecord,
    updateRecord,
    deleteRecord,
    getData
  };
};

export default useSharedDataService;
