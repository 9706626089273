import { useContext, useCallback } from "react";
import { AuthContext } from "../../context/AuthContext";
import useHttpClient from "../../hooks/useHttpClient";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router";

const useAuthService = () => {
  const { sendRequest, setToken } = useHttpClient();
  const { signIn, signOut } = useContext(AuthContext);

  const navigate = useNavigate();

  const login = useCallback(async (data) => {
    try {
      const response = await sendRequest("api/token/login", "post", data);
      // console.log(response);
      if (response?.data && response.data?.access) {
        setToken(response.data);
        const decodedToken = jwt_decode(response.data.access);
        const userData = await sendRequest(
          `api/user/get/${decodedToken.user_id}`
        );

        const user = userData.data;

        if (!(user.is_agent || user.is_gest || user.is_resp)) {
          throw new TypeError("Wrong user data");
        }

        if ((user.is_resp || user.is_agent) && !user.commune) {
          throw new TypeError("Wrong user data");
        }

        const dataToStore = {
          id: user.id,
          nom: user.nom,
          groups: user.groups
            ? user.groups
            : [],
          is_agent: user.is_agent,
          is_gest: user.is_gest,
          is_resp: user.is_resp,
          commune: user.commune ? user.commune.id : 0,
          gouv: user.commune ? user.commune.gouv : 0,
        };

        const result = await signIn(dataToStore, user.commune);

        if (result) {
          navigate("/index");
        } else {
          throw new TypeError("Wrong user data");
        }
      }
    } catch (err) {
      setToken(null);

      if (err.name === 'TypeError') {
        toast.error("Données d'identification mal définies. Prière contacter l'ANME pour actualiser vos données.");
      } else {
        toast.error("Données de connexion (adresse mail ou mot de passe) incorrectes.");
      }
    }
  }, [navigate, signIn, sendRequest, setToken]);

  const logout = useCallback(async () => {
    const invalidateToken = async (refreshToken) => {
      return await sendRequest("api/token/logout", "post", {
        refresh_token: refreshToken
      }, {withCredentials: true});
    }

    try {
      const token = JSON.parse(sessionStorage.getItem('token'));

      if (token &&  token?.refresh) {
        const response = await invalidateToken(token.refresh);
        console.log('logout', response);
      }
    } catch (e) {
      console.log('logout not working')
    } finally {
      signOut();
      navigate("/index");
    }

  }, [navigate, signOut, sendRequest]);

  return { login, logout };
};

export default useAuthService;
