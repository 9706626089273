import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import Loadable from "../_utils/Loadable";

const CommuneDashboardIndex = Loadable(
  lazy(() =>
    import("../views/dashboard/communes"))
);

const GouvDashboardIndex = Loadable(
  lazy(() =>
    import("../views/dashboard/gouvernorats"))
);

const NationalDashboardIndex = Loadable(
  lazy(() =>
    import("../views/dashboard/national"))
);

// ===========================|| PUBLIC ROUTING ||=========================== //

function PublicRoutes() {
  const routes = [
    {
      path: "/dashboards/public",
      element: <Outlet />,
      children: [       
        {
          path: "commune",
          element: <CommuneDashboardIndex fromPublic={true} />,
        },
        {
          path: "gouvernorat",
          element: <GouvDashboardIndex fromPublic={true} />,
        },
        {
          path: "national",
          element: <NationalDashboardIndex fromPublic={true} />,
        },
      ]
    }
  ];

  return routes;
}

export default PublicRoutes;
