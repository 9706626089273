const url = process.env.REACT_APP_API_SERVER_URL;

export function verifMedia(path) {
  if (!path.startsWith("/media")) {
    path = "/media" + path;
  }
  return path;
}

export const getMediaUrl = (path) => {
  if (path) {
    if (!path.startsWith("/media")) {
      path = "/media/" + path;
    }

    return url  + path;
    // return (url ? url : "http://127.0.0.1:8000") + path;
  }
  return path;
}
