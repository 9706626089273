import { useCallback } from "react";
import useSharedDataService from "../shared/dataService";

const useCommunesService = () => {
  const {
    updateRecord,
    getData
  } = useSharedDataService();

  const getCommunesByGouv = useCallback(async (idGouv) => {
    return getData(
      "api/communes/communes/" + idGouv,
      "commune",
      "l'extraction de la liste des communes",
    );
  }, [getData]);

  const getCommuneById = useCallback(async (id) => {
    return getData(
      "api/communes/communes/get/" + id,
      "commune",
      "l'extraction des données de la commune"
    );
  }, [getData]);

  const getCommuneInfoById = useCallback(async (id) => {
    return getData(
      "api/communes/communes/info/get/" + id,
      "commune",
      "l'extraction des données de la commune"
    );
  }, [getData]);

  const updateCommuneInfo = useCallback(async (id, data) => {
    return updateRecord(
      "api/communes/communes/info/update/" + id,
      "commune",
      "Commune: " + data.nom,
      data
    );
  }, [updateRecord]);

  return { 
    getCommuneById,
    getCommunesByGouv,
    getCommuneInfoById, 
    updateCommuneInfo };
};

export default useCommunesService;
