import { useMemo } from "react";

import { useRoutes, Navigate } from "react-router-dom";

import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
// import { useNavigate, useLocation } from "react-router";

import Home from "../home";
import Root from "../layout";
import Login from "../views/login/login";
import ErrorPage from "../views/erorrs";

import PrivateRoutes from "./privateRoutes";
import PublicRoutes from "./publicRoutes";


// ===========================|| ROUTING RENDER ||=========================== //

export default function ThemeRoutes() {
  const { loggedIn, commune } = useContext(AuthContext);
  // const navigate = useNavigate();
  // const location = useLocation();

  // useEffect(() => {
  //   if (loggedIn && location.pathname === "/login") navigate("/commune");
  //   if (!loggedIn) navigate("/login");
  // }, [loggedIn]);

  const routes = useMemo(() => [
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          errorElement: <ErrorPage />,
          children: [
            { index: true, element: <Home /> },
            {
              path: "/index",
              element: <Home />,
            },
          ].concat(
            !loggedIn ? [
              {
                path: "/login",
                element: <Login />,
              }
            ] : []
          ).concat(
            loggedIn && commune
              ? PrivateRoutes()
              : PublicRoutes()
          ).concat([
            { path: "/404", element: <ErrorPage status={404} /> },
            { path: "/401", element: <ErrorPage status={401} /> },
            { path: "/403", element: <ErrorPage status={403} /> },
            { path: "/*", element: <Navigate to="/404" /> },
          ])
        },
      ]
    },
    // ,
    // ,


  ], [loggedIn, commune]);


  return useRoutes(routes);
}
